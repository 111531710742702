<template>
  <section class="reporting">
    <Header />
    <!-- <div class="container"> -->
    <!-- <iframe
      width="100%"
      height="3050px"
      src="https://app.powerbi.com/reportEmbed?reportId=f8443e59-ada1-4a17-bc84-11dee23d3e42&autoAuth=true&ctid=9b9c2d4d-bfe5-4326-a44b-e0d91ff34837&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
      frameborder="0"
      allowfullscreen="true"
      class="margin__md"
    ></iframe>-->
    <iframe
      width="100%"
      height="3050"
      src="https://app.powerbi.com/view?r=eyJrIjoiOGU3MDc0ZjItYWFhNi00Y2Y5LWJhNTgtZmVjOGU1NWNhNDlmIiwidCI6IjliOWMyZDRkLWJmZTUtNDMyNi1hNDRiLWUwZDkxZmYzNDgzNyIsImMiOjN9"
      frameborder="0"
      allowfullscreen="true"
      class="margin__md"
    ></iframe>
    <!-- <Filters />
      <div class="flex__between">
        <h2 class="margin__sm">Results</h2>
        <button class="btn btn__sm btn__green">Export</button>
      </div>
      <div class="reporting-tiles flex__center flex__wrap m-tb-sm">
        <ValueTile bgcolor="#3c4e7d" heading="Number of Orders" value="190" />
        <ValueTile bgcolor="#3c4e7d" heading="Total Revenue" value="$7000.00" />
        <ValueTile bgcolor="#3c4e7d" heading="Total Profit" value="$2000.00" />
      </div>
      <div class="reporting-short-tiles m-tb-sm">
        <ShortTile heading="% Gift Cards" value="65.00%" />
        <ShortTile heading="% Inventoried" value="25.15%" />
      </div>
      <div class="reporting-charts--list margin__md">
        <Chart heading="Profit" />
        <Chart heading="Revenue" />
        <Chart heading="Recipient Type" />
        <Chart heading="% Gift Card" />
        <Chart heading="% Bulk" />
        <Chart heading="% Respa" />
    </div>-->
    <!-- </div> -->
  </section>
</template>

<script>
import Header from "./Header";
// import Filters from "./Filters";
// import ValueTile from "./ValueTile";
// import ShortTile from "./ShortTile";
// import Chart from "./Chart";
export default {
  components: {
    Header
    // Filters,
    // ValueTile,
    // ShortTile,
    // Chart
  },
  metaInfo: {
    title: "View Reporting | WOW"
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.reporting-short-tiles {
  @include grid($cols: 1fr, $col-gap: 15px, $row-gap: 15px, $align: center);
}
.reporting-charts--list {
  @include grid($cols: 1fr, $col-gap: 25px, $row-gap: 25px);
}
@media (min-width: $sm) {
  .reporting-short-tiles {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $md) {
  .reporting-charts--list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .reporting-tiles {
    @include grid($cols: repeat(3, 1fr), $col-gap: 15px, $row-gap: 15px);
  }
}
</style>