<template>
  <section class="reporting-header">
    <div class="container">
      <router-link to="/admin" class="breadcrumb">
        <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Admin
      </router-link>
      <h1 class="text__center padding__sm">WOW Reporting</h1>
      <p
        class="header--message"
      >The below charts display information about how the WOW Desk is performing. Use the sort and filter options to change what data is used when displaying the charts and what data will be exported.</p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
</style>